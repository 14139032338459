export const MEMBERS = [
  {
    username: 'aniketraj314',
    firstName: 'Aniket',
    lastName: 'Raj',
    title: 'Community',
    image: 'aniket.jpg',
    links: {
      twitter: 'https://twitter.com/AniketRaj314',
      website: 'https://aniketraj.me',
      email: 'aniket@devfolio.co',
    },
  },
  {
    username: 'faraday',
    firstName: 'Anish',
    lastName: 'Dhiman',
    title: 'Design',
    image: 'anish.jpg',
    links: {
      twitter: 'https://twitter.com/faradayfury',
      website: 'https://faradayfury.webflow.io',
      email: 'anish@devfolio.co',
    },
  },
  {
    username: 'starwiz_7',
    firstName: 'Aryan',
    lastName: 'Yadav',
    title: 'Engineering',
    image: 'aryan.jpg',
    links: {
      github: "https://github.com/starwiz-7",
      twitter: "https://twitter.com/7Aryany",
      email: "aryan@devfolio.co"
    },
  },
  {
    username: 'prathamvaidya',
    firstName: 'Pratham',
    lastName: 'Vaidya',
    title: 'Engineering',
    image: 'pratham.png',
    links: {
      website: "https://prathamvaidya.in",
      github: "https://github.com/prathamVaidya",
      linkedin: 'https://www.linkedin.com/in/prathamvaidya',
    },
  },
  {
    username: 'anshumansingh',
    firstName: 'Anshuman',
    lastName: 'Singh',
    title: 'Operations',
    image: 'anshuman.jpg',
    links: {
      github: 'https://github.com/anshuman-dev',
      linkedin: 'https://www.linkedin.com/in/anshuman-singh-4537731a5',
      website: 'https://singhanshuman.hashnode.dev',
    },
  },
  {
    username: 'denver',
    firstName: 'Denver',
    lastName: 'D\'souza',
    title: 'CEO',
    image: 'denver.jpg',
    links: {
      linkedin: 'https://in.linkedin.com/in/denverjude',
      twitter: 'https://twitter.com/denverjude',
      email: 'denver.dsouza@devfolio.co',
    },
  },
  {
    username: '',
    firstName: 'Jhumri',
    lastName: '',
    title: 'Meow',
    image: 'jhumri.jpg',
    links: {
      website: 'https://devfolio.co/cat_gif',
      github: 'https://devfolio.co/cat_gif',
      twitter: 'https://devfolio.co/cat_gif',
    },
  },
  {
    username: 'preetjdp',
    firstName: 'Preet',
    lastName: 'Parekh',
    title: 'Engineering',
    image: 'preet.jpg',
    links: {
      github: 'https://github.com/preetjdp',
      website: 'https://pre.et/',
      email: 'preet@devfolio.co',
    },
  },
  {
    username: 'shriyakaranam',
    firstName: 'Shriya',
    lastName: 'Karanam',
    title: 'Partnerships',
    image: 'shriya.jpg',
    links: {
      linkedin: 'https://www.linkedin.com/in/shriya-k-54009815a/',
      twitter: 'https://twitter.com/shriyakaranam',
      email: 'shriya@devfolio.co',
    },
  },
  {
    username: 'swarnimw',
    firstName: 'Swarnim',
    lastName: 'Walvalkar',
    title: 'Engineering',
    image: 'swarnim.jpg',
    links: {
      github: 'https://github.com/SwarnimWalavalkar',
      website: 'https://swarnimwalavalkar.com',
      email: 'swarnim@devfolio.co',
    },
  },
  {
    username: 'vanshika03',
    firstName: 'Vanshika',
    lastName: 'Rattan',
    title: 'Marketing',
    image: 'vanshika.jpg',
    links: {
      twitter: 'https://twitter.com/VanshikaRattan',
      linkedin: 'https://www.linkedin.com/in/vanshika-rattan/',
      email: 'vanshika@devfolio.co',
    },
  },
];

export const ADVISORS = [
  {
    firstName: 'Akash',
    lastName: 'Nimare',
    title: 'Product',
    image: 'akash.jpg',
    links: {
      website: 'https://akashnimare.in',
      github: 'https://github.com/akashnimare',
      email: 'akash.nimare@devfolio.co',
    },
  },
  {
    firstName: 'Nishant',
    lastName: 'Verma',
    title: 'Design',
    image: 'nishant.jpg',
    links: {
      website: 'https://nashvail.me',
      twitter: 'https://twitter.com/nashvail',
      email: 'nishant.verma@devfolio.co',
    },
  },
  {
    firstName: 'Utkarsh',
    lastName: 'Verma',
    title: 'Engineering',
    image: 'utkarsh.jpg',
    links: {
      github: 'https://www.github.com/utkarsh-var',
      twitter: 'https://twitter.com/utkarsh_var',
      email: 'utkarsh.verma@devfolio.co',
    },
  }
];

export const IN_MEMORY_OF = {
  firstName: 'Shakti',
  lastName: 'Goap',
  title: 'Founder',
  image: 'shakti.jpg',
  links: {
    heart: 'https://devfolio.co/blog/remembering-shakti/',
  }
};
