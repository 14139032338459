import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import ExternalLink from '@components/ExternalLink';
import Icon from '@components/Icon';

import { breakpoint } from '@constants';
import yellowStarSrc from '+static/elements/yellow-star.svg';

import theme from './Theme';

const LinkIcon = {
  email: <Icon name="mail" />,
  twitter: <Icon name="twitter" />,
  website: <Icon name="globe" />,
  github: <Icon name="github" />,
  linkedin: <Icon name="linkedin" />,
  instagram: <Icon name="instagram" />,
  heart: <Icon name="heart" />,
};

const Links = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.75em;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: #ebeeff;
    color: #759ef0;
    border-radius: 26px;
  }

  a > svg {
    height: 20px;
    width: 20px;
  }

  a:active {
    background-color: #2f4ae4;
    color: #ffffff;
  }

  a:hover {
    background-color: #3770ff;
    color: #ffffff;
  }

  a + a,
  a + span {
    margin-left: 8px;
  }

  @media screen and (max-width: ${breakpoint.max.sm}) {
    justify-content: center;
  }
`;

const MemberContainer = styled.article`
  background-color: ${theme.color.silver};
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid  #E4EAEB;
  width: 100%;
  max-width: 220px;
  box-sizing: border-box;
  @media (max-width: ${breakpoint.max.sm}) {
    max-width: 150px;
    background-color: transparent;
    gap: 16px;
    padding: 0;
    border: none;
  }
`;

const MemberDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-top: -12px;
  position: relative;
`

const MemberName = styled.div`
  background-color: ${theme.color.blue};
  padding: 6px 12px;
  border-radius: 4px;
  > h3 {
     color: white;
     margin: 0;
     font-size: 18px;
     font-weight: 800;
     @media (max-width: ${breakpoint.max.sm}) {
        font-size: 17px;
     }
   }
`;

const Title = styled.h4`
  font-family: ${theme.font.secondary};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.44px;
  color: ${theme.color.grey};
  text-transform: uppercase;
  margin: 0;
  @media (max-width: ${breakpoint.max.sm}) {
    font-size: 10px;
  }
`;

const StarElement = styled.img`
  position: absolute;
  z-index: -1;
  right: -42px;
  top: -46px;
  width: 118px;
  height: 118px;
  @media (max-width: ${breakpoint.max.sm}) {
    width: 85px;
    height: 85px;
    right: -16px;
    top: -26px;
  }
`;

const MemberImage = styled(Img)`
  border-radius: 50%;
  width: 156px;
  height: 156px;
  @media (max-width: ${breakpoint.max.sm}) {
    max-width: 120px;
    max-height: 120px;
  }
`;

const Flex = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Member = (props) => {
  const { firstName, lastName, title, fluid, links, hasStar, username } = props;
  const hasLinks = links && Object.keys(links).length > 0;

  return (
    <MemberContainer>
      {hasStar && <StarElement src={yellowStarSrc} alt="" />}
      <Flex href={username ? `https://devfolio.co/@${username}` : undefined} target={username ? "_blank": undefined}>
        <MemberImage
          fluid={fluid}
          alt={firstName}
          width={156}
          height={156}
        />
        <MemberDetails>
          <MemberName>
            <h3>{firstName}&nbsp;{lastName}</h3>
          </MemberName>
          <Title>{title}</Title>
        </MemberDetails>
      </Flex>
      {hasLinks &&
        (<Links>
          {Object.keys(links).map((linkType) =>
            <ExternalLink
              key={linkType}
              href={linkType === 'email' ? `mailto:${links[linkType]}` : links[linkType]}
              aria-label={`${firstName}'s ${linkType}`}
            >
              {LinkIcon[linkType]}
            </ExternalLink>
          )}
        </Links>)
      }
    </MemberContainer>
  );
};

export default Member;
