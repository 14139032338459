import React from 'react';

import Layout from '@components/Layout';

import Header from '@sections/Header';
import Team from '@sections/Team';
import JoinUs from '@sections/JoinUs';
import Footer from '@sections/Footer';

export default () => {
  return (
    <Layout title="About | Devfolio">
      <Header />

      <Team />
      <JoinUs />

      <Footer bgColor="lightSky" />
    </Layout>
  );
};
