import React from 'react';
import styled from 'styled-components';

import PlayOnVisible from '@components/PlayOnVisible';

import { breakpoint } from '@constants';
import useLottie from '@hooks/useLottie';

import beigeStarSrc from '+static/elements/beige-star.svg';
import blueArcSrc from '+static/elements/blue-arc.svg';
import blueOrbSrc from '+static/elements/blue-orb.svg';
// import coffeeOrbSrc from '+static/elements/coffee-orb.svg';
import tealOrbSrc from '+static/elements/teal-orb.svg';
// import yellowStarSrc from '+static/elements/yellow-star.svg';
import lightGreenOrb from '+static/elements/light-green-orb.svg';

import charCoffeeLottieSrc from '+static/lottie/character-coffee.json';
// import charGreenLottieSrc from '+static/lottie/character-green.json';
import charYellowLottieSrc from '+static/lottie/character-yellow.json';

const config = {
  loop: true,
};

const ELEMENTS = {
  HERO: [
    {
      src: beigeStarSrc,
      css: 'top: -124px; left: 32%; transform: scale(0.56)',
      id: 'beige-star',
    },
    {
      src: blueArcSrc,
      css: 'right: 10%; top: -96px',
    },
    {
      src: tealOrbSrc,
      css: 'left: 236px; top: 64%',
    },
    // {
    //   src: coffeeOrbSrc,
    //   css: 'right: 7%; bottom: -14rem',
    // },
    // {
    //   src: blueArcSrc,
    //   css: 'bottom: -180px; left: 24px; transform: rotate(60deg)',
    //   id: 'blue-arc',
    // },
  ],
  MEMBERS: [
    {
      src: lightGreenOrb,
      css: 'top: 24em; left: -2em;',
    },
    // {
    //   src: yellowStarSrc,
    //   css: 'right: 24em; top: 105em; transform: scale(1.4)',
    // },
    {
      src: tealOrbSrc,
      css: 'top: 48em; left: -1em;',
    },
    {
      src: blueOrbSrc,
      css: 'right: 0em; top: 127em',
    },
    // {
    //   src: coffeeOrbSrc,
    //   css: 'left: 12em; top: 68em; transform: scale(1.4)',
    // },
    {
      src: blueArcSrc,
      css: 'right: 0em; top: 77em; transform: rotate(35deg)',
    },
    // {
    //   src: tealOrbSrc,
    //   css: 'left: 26em; bottom: 72em; transform: scale(2)',
    // },
    // {
    //   src: beigeStarSrc,
    //   css: 'right: 8em; bottom: 86em',
    // },
    // {
    //   src: coffeeOrbSrc,
    //   css: 'right: 9em; bottom: 54em; transform: scale(1.4)',
    // },
  ],
};

const ImgElement = styled.img`
  position: absolute;
  z-index: -1;

  @media (max-width: ${breakpoint.max.md}) {
    display: none;

    &#blue-arc {
      display: block;
      left: -6rem;
      top: 20rem;
      transform: rotate(28deg);
    }

    &#beige-star {
      display: block;
      right: -6rem;
      left: auto;
      top: 2.4rem;
    }
  }
  @media (max-width: ${breakpoint.max.sm}) {
    &#beige-star {
      right: -4rem;
    }
  }
`;

const DivElement = styled.div`
  position: absolute;
  max-height: 188px;
  max-width: 188px;

  &#char-coffee-mobile,
  &#char-yellow-mobile {
    display: none;
  }

  @media (max-width: ${breakpoint.max.md}) {
    display: none;

    &#char-coffee-mobile,
    &#char-yellow-mobile {
      position: relative;
      display: block;
      max-height: 160px;
      max-width: 160px;
    }

    &#char-yellow-mobile {
      margin: 4rem auto 0;
    }

    &#char-coffee-mobile {
      margin: 0 auto 1rem;
    }
  }

  @media (max-width: ${breakpoint.max.sm}) {
    &#char-coffee-mobile,
    &#char-yellow-mobile {
      max-height: 105px;
      max-width: 105px;
    }
  }
`;

const Hero = () => {
  return (
    <>
      {ELEMENTS.HERO.map((element, index) => (
        <ImgElement key={index} alt="" css={element.css} {...element} />
      ))}
    </>
  );
};

const Members = () => {
  // const charGreenEl = React.useRef(null);
  const charCoffeeEl = React.useRef(null);
  // const charYellowEl1 = React.useRef(null);
  const charYellowEl2 = React.useRef(null);

  // useLottie(charGreenEl, charGreenLottieSrc, config);
  useLottie(charCoffeeEl, charCoffeeLottieSrc, config);
  // useLottie(charYellowEl1, charYellowLottieSrc, config);
  useLottie(charYellowEl2, charYellowLottieSrc, config);

  return (
    <>
      {ELEMENTS.MEMBERS.map((element, index) => (
        <ImgElement key={index} alt="" css={element.css} {...element} />
      ))}

      {/* <PlayOnVisible el={charGreenEl}>
        <DivElement ref={charGreenEl} css="right: -0.5em; top: 58em" />
      </PlayOnVisible> */}

      <PlayOnVisible el={charCoffeeEl}>
        <DivElement ref={charCoffeeEl} css="right: -8em; top: 40em" />
      </PlayOnVisible>

      {/* <PlayOnVisible el={charYellowEl1}>
        <DivElement ref={charYellowEl1} css="left: 11em; top: 107em" />
      </PlayOnVisible> */}

      <PlayOnVisible el={charYellowEl2}>
        <DivElement ref={charYellowEl2} css="left: -2em; top: 73em" />
      </PlayOnVisible>
    </>
  );
};

const MembersHeader = () => {
  const charCoffeeEl = React.useRef(null);
  useLottie(charCoffeeEl, charCoffeeLottieSrc, config);

  return (
    <PlayOnVisible el={charCoffeeEl}>
      <DivElement ref={charCoffeeEl} id="char-coffee-mobile" />
    </PlayOnVisible>
  );
};

const MembersFooter = () => {
  const charYellowEl = React.useRef(null);
  useLottie(charYellowEl, charYellowLottieSrc, config);

  return (
    <PlayOnVisible el={charYellowEl}>
      <DivElement ref={charYellowEl} id="char-yellow-mobile" />
    </PlayOnVisible>
  );
};

const TeamElements = {};

TeamElements.Hero = Hero;

TeamElements.MembersHeader = MembersHeader;
TeamElements.Members = Members;
TeamElements.MembersFooter = MembersFooter;

export default TeamElements;
