import React from 'react';
import styled from 'styled-components';

import { H1, P } from '@components/Typography';
import { Section, Container, Button } from '@components/_global';

const Flex = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 4em auto;
  max-width: 820px;
  text-align: center;
`;

const SectionPatterned = styled(Section)`
  background-color: #fafafa;
  background-image: url('/pattern-light.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const JoinUs = () => {
  return (
    <SectionPatterned id="join-us">
      <Container>
        <Flex>
          <H1 color="grey" css="margin: 32px 0">
            Join us in our <span css="color: #34afff">adventures</span>
          </H1>

          <P color="grey" size="large">
            Want to join us? We're actively hiring across the board. Get in touch, we would loooove
            to talk!
          </P>

          <div css="margin-top: 36px; display: flex; justify-content: center">
            <a href="https://devfolio.co/jobs" target="_blank" rel="noreferrer noopener">
              <Button appearance="primary">Get in touch</Button>
            </a>
          </div>
        </Flex>
      </Container>
    </SectionPatterned>
  );
};

export default React.memo(JoinUs);
